import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, share, takeUntil } from 'rxjs/operators';
import { DynamicFormGroup } from '../../../dynamic-forms';
import { PaginationDataModel } from '../../../models';
import { CustomDateTimePipe } from '../../../pipes';
import {
  CarefileService, ClientInfoService, IFormDropdownOption,
  IndividualInvolvementService, ITableOption, ITableView,
  PermissionService, SING_MODE, UserInfoService,
} from 'c4p-portal-util';

import { DOCUMENT_EMAIL_SHARING_TYPE, DOCUMENT_SHARING_TYPE } from '../../const/dropdowns';
import {
  ClientShareModel,
  DocumentModel,
  DocumentType,
} from '../../models/document.model';
import { ClientNetworkService } from '../../../services/client-network/client-network.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SECTION_TYPES } from '../../const/section-types.const';
import { CLIENT_PORTAL_USER_TYPE } from '../../../services/referent/client-portal-user-type/client-portal-user-type.service';

@Component({
  selector: 'app-sharing-detail',
  templateUrl: './sharing-detail.component.html',
  styleUrls: ['./sharing-detail.component.scss'],
})
export class SharingDetailComponent implements OnInit {
  @Input() data: any;
  public document: DocumentModel;
  public tableOptions: ITableOption = null!;
  public dynamicFormGroup: DynamicFormGroup;
  public sharedPersons$: BehaviorSubject<any>;
  public sharingForm: FormGroup = null!;
  DOCUMENT_SHARING_TYPE = DOCUMENT_SHARING_TYPE;
  CLIENT_PORTAL_USER_TYPE = CLIENT_PORTAL_USER_TYPE;
  public identificationMethodsForm: FormGroup = null;
  emailOptionsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  DOCUMENT_EMAIL_SHARING_OPTIONS = DOCUMENT_EMAIL_SHARING_TYPE;
  public clientEmailOrClientNetworkEmailForm = new FormControl();
  public individualInvolvementFilters: any;
  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 10,
    sortColumn: 'createdAt',
    sortDirection: 'desc',
    search: '',
  };
  public individualValues = [];
  public clientNetworkValues: any[] = [];
  public clientValues: any[] = [];
  public externalValues = [];
  public allIndividualValues = [];
  public allExternalValues = [];
  public emailOptions: any[] = [];
  public documentType: any;
  public alreadySignedUsers: any[] = [];
  enableSubmitButton = false;
  tempCountSharedPerson: any;
  public section: string;
  @Output() closeDetailComp = new EventEmitter<CloseDetailEventData>();
  selectedIndex = 0;
  sharedPersons: any[];
  signatureLogs: any[];
  public disableInternalAndExternalTabs = false;
  private hideSignReqColumn: boolean = false;
  constructor(
    private permissionService: PermissionService,
    private formBuilder: FormBuilder,
    private dateTimePipe: CustomDateTimePipe,
    public individualInvolvementService: IndividualInvolvementService,
    private carefileService: CarefileService,
    private clientNetworkService: ClientNetworkService,
    public clientService: ClientInfoService,
    private userService: UserInfoService,
  ) {
    this.sharedPersons$ = new BehaviorSubject<any>(null);
  }

  ngOnInit(): void {
    this.individualInvolvementFilters = {
      status: 'active',
      carefileId: this.data?.document.carefileId,
    };
    this.hideSignReqColumn = this.getHideSignReqColumn(this.data);
    this.tableOptions = this.buildTable();
    this.identificationMethodsForm = this.buildIdentificationMethodsForm();
    this.sharingForm = this.sharingBuildForm();
    this.document = this.data?.document;
    this.section = this.data?.section;
    if (this.section === SECTION_TYPES.APPLICATION) {
      this.DOCUMENT_SHARING_TYPE = DOCUMENT_SHARING_TYPE.filter(
        (type) => !['external', 'internal'].includes(type.value)
      );
    }
    this.sharingForm.get('sharingType').setValue('external');
    this.populateSharing(this.document);
    this.populateInternals();
    this.populateExternals();
    this.populateClientNetworks();

    this.setFormSubscriptions();
    this.checkSubmitButton();
    this.documentType = this.data?.document?.documentType;
    this.signatureLogs = this.data?.document?.signatureLogs;
    const clientNetwork = this.sharingForm.get('clientNetwork').value || [];
    const individualInvolments = this.sharingForm.get('individualInvolments').value || [];
    const sharing = this.sharingForm.get('sharing').value || [];

    this.sharedPersons = [
      ...clientNetwork,
      ...individualInvolments,
      ...sharing
    ];
    this.sharedPersons$.next({data: this.sharedPersons});
    this.tempCountSharedPerson = this.sharedPersons?.length;
  }

  onCloseDialog(): void {
    this.closeDetailComp.emit({ close: true, document: this.document });
  }
  onTabChange(tabChangeEvent: MatTabChangeEvent){
    this.selectedIndex = tabChangeEvent.index;
  }

  private readonly destroyed$ = new Subject<boolean>();
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get sharingFormArray(): FormArray {
    return this.sharingForm.get('sharing') as FormArray;
  }

  populateSharing(document: DocumentModel) {
    this.sharingFormArray.clear();
    const sharingList = document.sharing;
    this.alreadySignedUsers = sharingList.filter((share) => share.signatureStatus === SING_MODE.SIGNED);
    for (let existingShareSetting of sharingList) {
      let found = false;
      for (let shareSetting of sharingList) {
        if (shareSetting.id == existingShareSetting.id) {
          shareSetting.checked = true;
          found = true;
          break;
        }
      }
      if (!found) {
        let disabledEntry = Object.assign({}, existingShareSetting);
        disabledEntry.checked = true;
        disabledEntry.disabled = true;
        sharingList.push(disabledEntry);
      }
    }
    sharingList.sort((a, b) => {
      return a.fullName.localeCompare(b.fullName);
    });
    sharingList.forEach((sharingSetting) =>
      this.sharingFormArray.push(
        this.buildShareSettingFormGroup(sharingSetting),
      ),
    );
    this.sharedPersons$.next({ data: Object.values(sharingList) });
  }

  private buildShareSettingFormGroup(shareSetting: ClientShareModel) {
    let nameInfo = '';
    let signatureRequired: boolean;
    if (shareSetting) {
      nameInfo = shareSetting.fullName;
      signatureRequired = shareSetting.signatureRequired;
      if (shareSetting.shareDate) {
        nameInfo += ' ' + this.dateTimePipe.transform(shareSetting.shareDate);
      }
    }
    const shareSettingModelFormGroup = this.shareSettingModelBuildForm(
      shareSetting,
      nameInfo,
      signatureRequired
    );
    if (shareSetting?.disabled) {
      shareSettingModelFormGroup.disable();
    }

    return shareSettingModelFormGroup;
  }
  shareSettingModelBuildForm(shareSetting: ClientShareModel, nameInfo: string, signatureRequired: boolean) {
    return this.formBuilder.group({
      id: [shareSetting?.id],
      name: [nameInfo, Validators.required],
      checked: [!!shareSetting.checked],
      signatureRequired: [signatureRequired],
      sharingType: [shareSetting.sharingType],
      reminderPeriod: [shareSetting.reminderPeriod],
      expirationDate: [shareSetting.expirationDate],
    });
  }


  checkSubmitButton() {
    this.sharingForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (
          (this.sharingForm.get('sharingType').value === 'internal' &&
            this.sharingForm.get('individualInvolments')?.value?.length > 0) ||
          (this.sharingForm.get('sharingType').value === 'external' &&
            this.sharingForm.get('clientNetwork')?.value?.length > 0) ||
          this.compareDocumentSharingAndForm(
            this.sharingFormArray.getRawValue(),
          ) ||
          this.sharingForm.get('sharing').value.some((share: any) => share.signatureRequired)
        ) {
          this.enableSubmitButton = true;
        } else {
          this.enableSubmitButton = false;
        }
      });
  }
  compareDocumentSharingAndForm(sharingFormArray: any[]) {
    if (sharingFormArray?.length !== this.document?.sharing?.length) {
      return true;
    }
    const notEqualFound = sharingFormArray?.find((sfa) => {
      const found = this.document?.sharing?.find(
        (original) => original.id === sfa.id,
      );
      return !found || found.checked !== sfa.checked;
    });
    return notEqualFound;
  }

  /**Input comes as an object : { value, element, columnId } */
  onNumberInputChanged(event: any) {
    if (event.value) {
      this.document.sharing?.forEach((sharing: any) => {
        if (
          sharing.fullName &&
          event.element?.fullName?.includes(sharing.fullName)
        ) {
          sharing.reminderPeriod = parseInt(event.value);
        }
      });
      this.sharedPersons.forEach((person) => {
        const formGroup = this.sharingFormArray.controls.find(
          (control) => control.get('name')?.value === person.fullName,
        );
        if (formGroup) {
          formGroup.get('reminderPeriod')?.setValue(person.reminderPeriod);
        }
      });
    }
  }

  onDateInputChanged(event: any) {
    const date = event.inputDate;
    if (date) {
      this.document.sharing?.forEach((sharing: any) => {
        if (
          sharing.fullName &&
          event.element?.fullName?.includes(sharing.fullName)
        ) {
          sharing.expirationDate = date;
        }
      });
      this.sharedPersons?.map((person: any) => {
        const formGroup = this.sharingFormArray.controls.find(
          (control) => control.get('name')?.value === person.fullName,
        );
        if (formGroup) {
          formGroup.get('expirationDate')?.setValue(person.expirationDate);
        }
      });
    }
  }

  submit() {

    const otherFormGroupMissingPersons = this.sharedPersons.filter(p => !this.sharingFormArray.getRawValue().some(control => control.name === p.fullName));
    otherFormGroupMissingPersons.forEach(person => {
      this.sharingFormArray.push(this.shareSettingModelBuildForm(person, person.fullName, person.signatureRequired));
    });

    const data = this.sharingForm.getRawValue();
    console.log("submit data",data);
    
    let addToShare: ClientShareModel[] = [];
    if (this.sharingForm.valid) {
      data.clientNetwork?.map((clientNetwork) => {
        addToShare.push({
          id: data.id,
          userId: clientNetwork.id,
          fullName: clientNetwork.fullName,
          shareDate: new Date(),
          sharingType: 'external',
          checked: true,
          signatureRequired: data.signatureRequired,
          reminderPeriod: data.sharing.find(
            (share: ClientShareModel) => share.id === clientNetwork.id,
          ).reminderPeriod,
          expirationDate: data.sharing.find(
            (share: ClientShareModel) => share.id === clientNetwork.id,
          ).expirationDate,
        });
      });
      data.individualInvolments?.map((individualInvolment) => {
        addToShare.push({
          id: data.id,
          userId: individualInvolment.id,
          fullName: individualInvolment.fullName,
          shareDate: new Date(),
          sharingType: 'internal',
          checked: true,
          signatureRequired: data.signatureRequired,
          reminderPeriod: data.sharing.find(
            (share: ClientShareModel) => share.id === individualInvolment.id,
          ).reminderPeriod,
          expirationDate: data.sharing.find(
            (share: ClientShareModel) => share.id === individualInvolment.id,
          ).expirationDate,
        });
      });
      const ids = new Set(this.document.sharing.map((share) => share.userId));
      this.document.sharing = [
        ...this.document.sharing.filter(
          (share, idx) => share.checked === data.sharing[idx].checked,
        ),
        ...addToShare.filter((share) => !ids.has(share.userId)),
      ];
       this.document.sharing = this.document.sharing.map(item => {
         let matchedItem = data.sharing?.find(element => element.name.split(' ').slice(0,2).join(' ') === item.fullName.split(' ').slice(0,2).join(' '));
        if(!matchedItem){
          matchedItem = data.individualInvolments?.find(element => element.fullName.split(' ').slice(0,2).join(' ') === item.fullName.split(' ').slice(0,2).join(' '));
        }
       if (!matchedItem) {
         matchedItem = data.clientNetwork?.find(element => element.fullName.split(' ').slice(0, 2).join(' ') === item.fullName.split(' ').slice(0, 2).join(' '));
       }
         item.signatureRequired = matchedItem?.signatureRequired ? matchedItem.signatureRequired : false;
         item.expirationDate = matchedItem?.expirationDate ? matchedItem.expirationDate : null;
         return item;
      });
      this.sharedPersons$.next({data: Object.values(this.sharedPersons)})
      this.onCloseDialog();
    } else {
      this.sharingForm.markAllAsTouched();
    }
  }

  private setFormSubscriptions(): void {
    this.sharingForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        const clientNetwork = this.sharingForm.get('clientNetwork').value || [];
        const individualInvolments = this.sharingForm.get('individualInvolments').value || [];
        const sharing = this.sharingForm.get('sharing').value || [];
        const extras = [];

        const currentSharedPersons = this.sharedPersons$.getValue();

        individualInvolments.forEach(inv => {
          if (!currentSharedPersons.data.some(shr => shr.id === inv.id))
            extras.push({
              ...inv
            });
        })
        clientNetwork.forEach(cn => {
          if (!currentSharedPersons.data.some(shr => shr.id === cn.id))
            extras.push({
              ...cn
          });
        })

        if(currentSharedPersons?.data.length > 0){
          currentSharedPersons.data = currentSharedPersons?.data.filter(cp => {
            const existInClient = clientNetwork.some(cn => cn.id === cp.id);
            const existInII = individualInvolments.some(ii => ii.id === cp.id);
            return existInClient || existInII;
          })
        }

        this.sharedPersons = [
          ...sharing,
          ...currentSharedPersons.data,
          ...extras
        ];
        this.sharedPersons$.next({ data: this.sharedPersons });
      });

    this.sharingForm
      .get('toggleAll')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((toggleAll) => {
        this.document.sharing.forEach((share, idx) => {
          this.sharingFormArray.at(idx).patchValue({ checked: toggleAll });
        });
      });

    this.clientEmailOrClientNetworkEmailForm.valueChanges.subscribe(value => {
      if(value === 'clientEmail'){
        this.emailOptions = this.clientValues;
      }
      else {
        this.emailOptions = this.clientNetworkValues;
      }
      this.emailOptionsSubject.next(this.emailOptions)
    })
  }

  private populateInternals() {
    const currentUserId = this.userService.userId;
    this.individualInvolvementService
      .getCarefileAccessManagements(
        new PaginationDataModel('createdAt', 'desc', 999),
        this.individualInvolvementFilters,
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((involvements: any) => {
        Array.from(new Set(involvements.docs.map((inv) => inv.employee.id)))
          .filter((id) => {
            return (
              !this.document.sharing.some((shared) => shared.userId === id) &&
              id !== this.document.createdBy.id && currentUserId !== id
            );
          })
          .map((id) => {
            const involment = involvements.docs.find(
              (inv) => inv.employee.id === id,
            );
            if(involment.status  === 'active'){
              this.individualValues.push({
                fullName: involment.employee.fullName,
                id: involment.employee.id,
              });
            }
            this.allIndividualValues.push({
              fullName: involment.employee.fullName,
              id: involment.employee.id,
            })
            return;
          });
      });
  }


  private populateClientNetworks(){
    const carefileId: string = this.document.carefileId;
    const tenantId: string = this.document.tenantId;

    this.carefileService.getCarefileById(carefileId,tenantId).subscribe((carefile) => {
      this.clientService
        .getClientDetail(carefile.clientId)
        .subscribe((client) => {
          if(!this.externalValues.find(ext => ext.id === client.id) && !this.document.sharing.some(shr => shr.userId === client.id)){
            this.clientValues.push({
              fullName: client.fullName,
              email: client.email,
              id: client.id
            })
          }
        });

      const ids = carefile.clientNetworks.map(clientNetwork => clientNetwork.id).join(',');
      const filters = {
        page: '1',
        limit: '10',
        sortColumn: 'createdAt',
        sortDirection: 'desc',
        ids: ids,
        entityType: 'carefile',
        entityId: carefileId,
      };
      this.clientNetworkService.getClientNetworks(PaginationDataModel.fromTableView(this.tableView),filters).subscribe(clientNetworks => {
        clientNetworks.docs.forEach(cn => {
          if(cn.emails.length > 0){
            if(!this.externalValues.find(ext => ext.id == cn.id) && !this.document.sharing.some(shr => shr.userId === cn.id)){
              this.clientNetworkValues.push({
                fullName: cn.name,
                id: cn.id,
                email: cn.emails[0].email,
              })
            }
          }
        })
      });
    })
  }

  private populateExternals() {
    const filter = {
      ...this.individualInvolvementFilters,
      type: 'client,client_network',
    };
    this.individualInvolvementService
      .getCarefileAccessManagements(
        new PaginationDataModel('createdAt', 'desc', 999),
        filter,
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((involvements: any) => {
        Array.from(
          new Set(
            involvements.docs.map((inv) => {
              if (inv.type) {
                if (inv.type === 'client_network' && inv.clientNetwork) {
                  return inv.clientNetwork.id;
                } else if (inv.type === 'client' && inv.client) {
                  return inv.client.id;
                } else {
                  // should not get here
                  return '';
                }
              }

              return inv.clientNetwork ? inv.clientNetwork.id : inv.client.id;
            }),
          ),
        )
          .filter((id) => {
            return (
              !this.document.sharing.some((shared) => shared.userId === id) &&
              id !== this.document.createdBy.id
            );
          })
          .map((id) => {
            const client = involvements.docs.find((inv) => {
              // check if type field is present first (this should be the case only for client and client_network types of individual involvements)
              if (inv.type) {
                if (inv.type === 'client_network' && inv.clientNetwork) {
                  return inv.clientNetwork.id === id;
                } else if (inv.type === 'client' && inv.client) {
                  return inv.client.id === id;
                } else {
                  // should never get here
                  return false;
                }
              }

              // in case type was not present due to malformed or badly migrated data
              if (inv.clientNetwork) {
                return inv.clientNetwork.id === id;
              }
              return inv.client.id === id;
            });

            if (client.type) {
              const candidate = { fullName: '', id: '', sharingType:'' };

              if (client.type === 'client_network') {
                candidate.fullName = client.clientNetwork
                  ? client.clientNetwork.fullName
                  : '';
                candidate.id = client.clientNetwork
                  ? client.clientNetwork.id
                  : '';
              } else if (client.type === 'client') {
                candidate.fullName = client.client
                  ? client.client.fullName
                  : '';
                candidate.id = client.client ? client.client.id : '';
              }
              if(client.status === 'active'){
                this.externalValues.push({...candidate, sharingType:'external'});
              }
              this.allExternalValues.push({...candidate, sharingType:'external'});
            } else {
              if(client.status === 'active'){
                this.externalValues.push({
                  fullName: client.clientNetwork
                    ? client.clientNetwork.fullName
                    : client.client.fullName,
                  id: client.clientNetwork
                    ? client.clientNetwork.id
                    : client.client.id,
                });
                this.allExternalValues.push({
                  fullName: client.clientNetwork
                    ? client.clientNetwork.fullName
                    : client.client.fullName,
                  id: client.clientNetwork
                    ? client.clientNetwork.id
                    : client.client.id,
                })
              }
            }
            return;
          });
      });
  }

  buildIdentificationMethodsForm(){
    return this.formBuilder.group({
      validateWithBsn:[],
      validateWithPhoneNumber:[],
      validateWithBirthYear:[],
      validateWithEmail:[],
      validateWithEmailCode:[],
    });
  }

  sharingBuildForm() {
    return this.formBuilder.group({
      sharing: this.formBuilder.array([]),
      sharingType: [null],
      clientNetwork: [null],
      individualInvolments: [null],
      validateWithEmailValidDays: [null],
      emailValidDays: [],
      signatureRequired: [],
      type: [null],
      client: this.formBuilder.group({
        id: [],
        fullName: [{ disabled: true, value: null }]
      }),
      toggleAll: [{ value: true }],
    });
  }
  singleCheck(event: any, person: any) {
    const existingIndex = this.sharedPersons.findIndex(p => p.fullName === person.fullName);

    if (existingIndex !== -1) {
      this.sharedPersons[existingIndex].signatureRequired = event;
      this.sharedPersons[existingIndex].reminderPeriod = event ? 7 : null;
      if(!event){
        this.sharedPersons[existingIndex].expirationDate = null;
      }

      const formGroupIndex = this.sharingFormArray.getRawValue().findIndex(control => control.name === person.fullName);
      if (formGroupIndex !== -1) {
        this.sharingFormArray.at(formGroupIndex).get('signatureRequired').patchValue(event);
      } else {
        console.error(`Form group for ${person.fullName} not found.`);
      }
    } else {
      this.sharedPersons.push({
        ...person,
        signatureRequired: event
      });
    }
    this.sharedPersons$.next({data: this.sharedPersons});
  }




  buildTable(): ITableOption {
    return {
      showHeader: true,
      externPagination: true,
      defaultPageSize: 20,
      defaultSortColumn: 'createdAt',
      showTitle: false,
      defaultSortDirection: 'desc',
      dataObservable: this.sharedPersons$.pipe(map((data) => {
        data.data.map(item => {
          this.document.sharing.forEach(sharing => {
            if (sharing.id === item.id){
              item.signatureStatus=sharing.signatureStatus
            }
          })
          if(item && item.name){
            item.fullName = item.name;
          }
          if(item.sharingType==="external"){
            item.external= true;
          }
          if(!item.signatureRequired){
            item.checked = true;
            item.reminderPeriod=null;
            item.expirationDate=null;
          } else if (item.signatureRequired && !item.reminderPeriod){
            item.reminderPeriod = 7;
            item.checked = true;
          }
        })
        data.data = data.data.filter(person => !this.alreadySignedUsers.some(signedUser => signedUser.id === person.id));
        return data;
      })),
      columns: [
        { id: 'external', name: 'sharingType', type: 'externalIcon', format: 'link', width:  10, iconTooltip:'document.labels.externalUser' },
        { id: 'fullName', name: 'document.labels.Name', type: 'string', format: '', width: 180 },
        { id: 'signatureRequired', name: 'document.labels.signatureRequired', type: 'checkbox', format: 'rowSelect', width: 100, hideColumn :this.hideSignReqColumn, hideCondition: (data) => this.isSignatureRowHide(data)},
        { id: 'reminderPeriod', 
          name: 'document.labels.reminderPeriod', 
          type: 'numberInput', format: 'numberInput', 
          width: 140, 
          hideCondition: (data) => this.isReminderRowHide(data),
          hideColumn: this.hideSignReqColumn
        },
        { id: 'expirationDate', 
          name: 'document.labels.expirationDate', 
          type: 'dateInput', 
          format: 'dateInput', 
          hideCondition: (data) => this.isReminderRowHide(data),
          width: 140,
          hideColumn: this.hideSignReqColumn,
        },
      ]
    };
  }

  protected readonly DocumentType = DocumentType;

  private getHideSignReqColumn(data: any): boolean {
    return !(
      (this.data?.document?.documentType === 'pdf' ||
        this.data?.document?.fileData?.extension === 'pdf') &&
      this.permissionService.getPermission(
        'carefile:files:digitalSignature:signatureRequired',
        'or',
      )
    );
  }

  private isReminderRowHide(data: any): boolean {
    return !data.signatureRequired || data.signatureStatus === 'signed' || data.signatureStatus === 'expired';
  }

  private isSignatureRowHide(data: any): boolean {
    return data.signatureStatus === 'signed' || data.signatureStatus === 'expired';
  } 

}
export interface CloseDetailEventData {
  close: boolean;
  document: DocumentModel;
}
