@if (column().enableFiltering) {
  @if (column()?.filter?.filterTemplate) {
    <ng-container style="width:100%" *ngTemplateOutlet="column().filter.filterTemplate; context: { column: column }"></ng-container>
  } @else {

    @if(column()){
      <div style="width: 100%" >
        @if (column()?.filter?.type === 'text' || column()?.filter?.type === 'number') {
          <mat-form-field appearance="outline" class="table-header-filter-form-input-field">
            <input matInput [formControl]="valueControl">
          </mat-form-field>
        } @else if (column()?.filter?.type === 'date' || column()?.filter?.type === 'dateTime') {
          @if (column()?.filter?.interval) {
            <mat-form-field appearance="outline" class="table-header-filter-form-input-field">
              <mat-date-range-input [formGroup]="dateIntervalGroup" [rangePicker]="rangepicker">
                <input matStartDate [formControl]="startDateControl">
                <input matEndDate [formControl]="endDateControl">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="rangepicker"></mat-datepicker-toggle>
              <mat-date-range-picker #rangepicker></mat-date-range-picker>
            </mat-form-field>
          } @else {
            <mat-form-field appearance="outline" class="table-header-filter-form-input-field">
              <input matInput [formControl]="valueControl" [matDatepicker]="datepicker" placeholder="{{ 'general.labels.SelectDate' | translate }}" (click)="datepicker.open()">
              <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker></mat-datepicker>
            </mat-form-field>
          }
        } @else if (column()?.filter?.type === 'time') {
          <mat-form-field appearance="outline" class="table-header-filter-form-field">
            <input matInput [formControl]="valueControl" (input)=formatTimeInput($event)
                   (keydown)=allowOnlyTimeInput($event) [placeholder]="'hh:mm'">
            <span matSuffix style="margin-right: 5px" [ngClass]="{'invalid-filter' : valueControl.invalid && valueControl.value}"></span>
          </mat-form-field>
        }
        @else if (column()?.filter?.type === 'select' || column()?.filter?.type === 'boolean') {
          <mat-form-field appearance="outline" class="table-header-filter-form-field">

            <mat-select [formControl]="valueControl" [multiple]="column()?.filter?.multiSelection" [panelWidth]="null">

              @if (column()?.filter?.loadOptions) {
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="searchControl"
                    [clearSearchInput]="false"
                    [searching]="isSearchResultLoading"
                    placeholderLabel="{{ 'general.actions.Search' | translate }}..."
                    noEntriesFoundLabel="{{ 'general.labels.Empty' | translate }}">
                  </ngx-mat-select-search>
                </mat-option>
              }

              @if (!column()?.filter?.multiSelection) {
                <mat-option></mat-option>
              }

              @for (option of column()?.filter?.options; track $index) {
                <mat-option [value]="option[column()?.filter?.optionValueProperty]">{{option[column()?.filter?.optionLabelProperty] | translate}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      </div>
    }
  }
} @else if (column().prop === 'menuActions') {
    <button class="btn-square-icon m-0 p-1" (click)="onClearFilters()" [tooltip]="'Reset Filter'" placement="bottom" tooltipDelay="100">
      <i class="ai-icon-refresh-ccw-01"></i>
    </button>
} @else if (!column().hideDefaultFilter) {
  <mat-form-field appearance="outline" class="table-header-filter-form-input-field">
    <input matInput [disabled]="true">
  </mat-form-field>
}
