<div class="tuula-table-main-container">

  <div class="tuula-table-container">
    <div style="display: flex; flex-direction: column">
      @if (showTopBar()) {
        <div class="tuula-table-top-bar" [ngStyle]="{'border-bottom': customTitleElement ? 0 : 1} ">
          <div class="tuula-table-title-container">
            @if (title()) {
              <span class="tuula-table-title">
          {{ title() | translate }}
        </span>
            } @else if (customTitleElement) {
              <div class="tuula-table-title-template">
                <ng-container *ngTemplateOutlet="customTitleElement">
                </ng-container>
              </div>
            }
          </div>
          <div class="tuula-table-controls">
            <div class="tuula-table-controls-sub-container">
              <ng-container *ngTemplateOutlet="tableControls()">
              </ng-container>
            </div>
            <div class="tuula-table-controls-sub-container">
              @if(tableFilteringButtonVisible()){
                <button class="btn-light" (click)="onToggleFilter()">
                  <i class="ai-icon-filter-funnel-02"></i>
                  <span class="tuula-table-filter-button-text">{{'notification.labels.Filters' | translate}}</span>
                  <i class="ai-icon-chevron-down" *ngIf="!filterPanelOpened"></i>
                  <i class="ai-icon-chevron-up" *ngIf="filterPanelOpened"></i>
                </button>
              }
              @if(tableSettingsVisible()){
                <button (click)="onOpenTableSettings()" class="btn-square-icon">
                  <i class="ai-icon-settings-02"></i>
                </button>
              }
            </div>
          </div>
        </div>
      }
      @if(title() && customTitleElement){
        <div class="tuula-table-title-template">
          <ng-container *ngTemplateOutlet="customTitleElement">
          </ng-container>
        </div>
      }
    </div>
  
    @if (tableLoading()) {
      <app-tuula-table-skeleton
      [columns]="columns()"
      [tableRowSize]="tableRowSize"
      [totalRowWidth] = "totalRowWidth"
      [defaultCellWidth]="defaultCellWidth"></app-tuula-table-skeleton>
    }
      <ng-container *ngTemplateOutlet="tuulaTable"></ng-container>
  
  
  </div>

  @if (showPagination()) {
    <app-table-pagination [pageSizeOptions]="pageSizeOptions()"
                   [length]="pagination()?.totalDocs"
                   (pageChange)="onPageChanged($event)"
                   [pageSize]="pagination()?.pageSize"
                   [pageIndex]="pagination()?.page - 1">
    </app-table-pagination>
  }
</div>



<ng-template #tuulaTable>
  <mat-table [dataSource]="dataSource" [attr.loading]="tableLoading() ? '' : null" class="tuula-table" matSort (matSortChange)="onSortChanged($event)" cdkDropList
             cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
    @for (column of columns(); track column.prop) {
      <ng-container [matColumnDef]="column.prop" [sticky]="!mobileView && column.frozenLeft"
                    [stickyEnd]="column.frozenRight">
        <mat-header-cell cdkDrag cdkDragLockAxis="x" cdkDragBoundary=".tuula-table"
                         [cdkDragDisabled]="(column.draggable === false)" [cdkDragStartDelay]="50" *matHeaderCellDef

                         [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': {width: column.width, minWidth: column.minWidth,mobileView,defaultCellWidth} | functionCall: getWidth, 'justify-content': column.justify ? column.justify :'start', 'text-align': column.justify ? column.justify : 'start' }">
          <div class="column-header-wrapper">
            @if (column.headerTemplate) {
              <ng-container *ngTemplateOutlet="column.headerTemplate; context: { column: column }">
              </ng-container>
            } @else {
              <span mat-sort-header [disabled]="(column.sortable === false ? '' : null) ">{{( column.hideColumnName ? '' : column.name) | translate }}</span>
            }
            @if (!mobileView && !(column.hideColumnName || column.resizeable === false)) {
              <span class="resizable" appResizableColumn [column]="column"
                    (columnWidthChanged)="onColumnResize($event)">|
              </span>
            }
          </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index"
        [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': {width: column.width, minWidth: column.minWidth,mobileView,defaultCellWidth} | functionCall: getWidth, 'justify-content': column.justify ? column.justify :'start' }">
        @if (column.cellTemplate) {
        <ng-container
          *ngTemplateOutlet="column.cellTemplate; context: { row: row, rowIndex: i, rowValue: {row, column} | functionCall : getNestedValue }">
        </ng-container>
        } @else {
        <span [tooltip]="{row, column} | functionCall : getNestedValue" placement="bottom" tooltipDelay="100">
          {{ {row, column} | functionCall : getNestedValue }}
        </span>
        }
      </mat-cell>
    </ng-container>

    <!-- Filter Columns -->
    <ng-container matColumnDef="{{column.prop + filterColumnNamePostfix}}" [sticky]="!mobileView && column.frozenLeft"
      [stickyEnd]="column.frozenRight">
      <mat-header-cell *matHeaderCellDef
        [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': {width: column.width, minWidth: column.minWidth,mobileView,defaultCellWidth} | functionCall: getWidth, 'justify-content': column.justify ? column.justify :'start' }">
        @if (filterPanelOpened){
        <app-tuula-table-filter-header-column style="max-width:100%" [column]="column"
          (filterChanged)="onApplyFilter($event)"
          (filterCleared)="onClearFilter()"></app-tuula-table-filter-header-column>
        }
      </mat-header-cell>
    </ng-container>
    }
    <mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: stickyMode"
      [ngStyle]="{ 'min-width.px': totalRowWidth }"></mat-header-row>
    <mat-header-row [ngClass]="{'invisible-header-row' : !filterPanelOpened}"
      *matHeaderRowDef="tableDisplayedFilterColumns, sticky: stickyMode"
      [ngStyle]="{ 'min-width.px': totalRowWidth }"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableDisplayedColumns;"
      [ngClass]="[tableRowSize === 'default'? 'tuula-table-row-default' : 'tuula-table-row-compact']"
      [ngStyle]="{ 'min-width.px': totalRowWidth }"
      (click)="onRowClick(row)"></mat-row>

    <tr class="mat-mdc-row mdc-data-table__row no-data-row" *matNoDataRow [ngStyle]="{ 'min-width.px': totalRowWidth }">
      <td class="mat-mdc-cell mdc-data-table__cell" translate>general.labels.NoRecordFound</td>
    </tr>

  </mat-table>
</ng-template>

